<template>
  <div class="travelSpecsLogDetails">
    <el-breadcrumb separator="/">
      <el-breadcrumb-item>日志详情</el-breadcrumb-item>
    </el-breadcrumb>
    <el-form ref="form" label-width="80px">
      <el-form-item label="线路名称"> {{ formList.travelName }}</el-form-item>
      <el-form-item label="套餐名称">
        {{ formList.travelSpecsName }}</el-form-item
      >
      <el-form-item label="修改人"> {{ formList.masterName }}</el-form-item>
      <el-form-item label="修改时间"> {{ formList.addTime }}</el-form-item>
      <el-form-item label="修改内容"> {{ formList.strType }}</el-form-item>

      <el-form-item label="	修改内容类型"> {{ formList.logType }}</el-form-item>
      <el-form-item label="修改前内容"> {{ formList.curContent }}</el-form-item>
      <el-form-item label="	修改后内容"> {{ formList.endContent }}</el-form-item>
    </el-form>
    <el-button type="primary" @click="$router.go(-1)">返回</el-button>
  </div>
</template>
<script>
import { travelSpecsLogDetails } from "../../api/travelSpecs";
export default {
  name: "travelSpecsLogDetails",
  props: {
    id: {
      type: [Number, String],
      required: true,
    },
  },
  data() {
    return {
      formList: {},
    };
  },
  created() {
    this.gettravelSpecsLogDetails();
  },
  methods: {
    async gettravelSpecsLogDetails() {
      const { data } = await travelSpecsLogDetails({
        logId: Number(this.id),
      });
      console.log(data);
      this.formList = data.data[0];
    },
  },
};
</script>
<style lang="less" scoped>
.travelSpecsLogDetails {
  .el-breadcrumb {
    height: 35px;
    border-bottom: 1px solid rgb(192, 191, 191);
  }
  /deep/ .el-form {
    margin-top: 50px;
    .el-form-item__label {
      width: 100px !important;
    }
  }
}
</style>